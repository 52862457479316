import Vue from 'vue';
import router from './router';
import store from "./store"
import App from "./components/App.vue";
import "tailwindcss/tailwind.css"
window.Vue = require("vue").default;
const app = new Vue({
    el: '#app',
    components: {
        App
    },
    router,
    store,
});

