import Vue from 'vue';
import VueRouter from 'vue-router';

const MainPage = () => import(/* webpackPreload: true */ /* webpackChunkName: "main" */ "./frontend/Main");
const AccountStatus = () => import(/* webpackPreload: true */ /* webpackChunkName: "login" */ "./frontend/AccountDelete");
const PriceList = () => import(/* webpackPreload: true */ /* webpackChunkName: "price-list" */ "./frontend/PriceList");
const AboutUs = () => import(/* webpackPreload: true */ /* webpackChunkName: "about-us" */ "./frontend/AboutUs");
const Functions = () => import(/* webpackPreload: true */ /* webpackChunkName: "functions" */ "./frontend/Functions");
const KnowledgeBaseParent = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-parent" */ "./frontend/KnowledgeBaseParent");
const KnowledgeBaseIndex = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-index" */ "./frontend/KnowledgeBaseIndex");
const KnowledgeBaseFaq = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-faq" */ "./frontend/KnowledgeBaseFaq");
const KnowledgeBaseFirstSteps = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-first-steps" */ "./frontend/KnowledgeBaseFirstSteps");
const KnowledgeBaseDrivers = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-drivers" */ "./frontend/KnowledgeBaseDrivers");
const KnowledgeBaseCouriers = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-couriers" */ "./frontend/KnowledgeBaseCouriers");
const KnowledgeBaseFleet = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-fleet" */ "./frontend/KnowledgeBaseFleet");
const KnowledgeBaseRoutes = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-routes" */ "./frontend/KnowledgeBaseRoutes");
const KnowledgeBaseInvoices = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-invoices" */ "./frontend/KnowledgeBaseInvoices");
const KnowledgeBaseBookings = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-bookings" */ "./frontend/KnowledgeBaseBookings");
const KnowledgeBaseAddressBook = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-address-book" */ "./frontend/KnowledgeBaseAddressBook");
const KnowledgeBaseBlackList = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-black-list" */ "./frontend/KnowledgeBaseBlackList");
const KnowledgeBaseMessages = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-messages" */ "./frontend/KnowledgeBaseMessages");
const KnowledgeBaseStatistics = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-statistics" */ "./frontend/KnowledgeBaseStatistics");
const KnowledgeBaseVideos = () => import(/* webpackPreload: true */ /* webpackChunkName: "kb-videos" */ "./frontend/KnowledgeBaseVideos");

const Idea = () => import(/* webpackPreload: true */ /* webpackChunkName: "idea" */ "./frontend/Idea");
const ContactDetails = () => import(/* webpackPreload: true */ /* webpackChunkName: "contact-details" */ "./frontend/ContactDetails");
const Terms = () => import(/* webpackPreload: true */ /* webpackChunkName: "terms" */ "./frontend/TermsAndConditions");
const PrivacyPolicy = () => import(/* webpackPreload: true */ /* webpackChunkName: "privacy-policy" */ "./frontend/PrivacyPolicy");

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',

    routes: [
        {
            name: 'home',
            path: '/',
            component: MainPage

        },
        {
            name: 'login',
            path: '/login',
            beforeEnter(to, from, next) {
                window.location.href = "https://panel.inteneo.pl/login";
            }
        },
        {
            name: 'status',
            path: '/status',
            component: AccountStatus
        },
        {
            name: 'rejestracja',
            path: '/rejestracja',
            beforeEnter(to, from, next) {
                window.location.href = "https://panel.inteneo.pl/rejestracja";
            }
        },
        {
            path: '/cennik',
            component: PriceList
        },
        {
            name: 'funkcje',
            path: '/funkcje',
            component: Functions
        },
        {
            name: 'idea',
            path: '/idea',
            component: Idea
        },
        {
            name: 'o-nas',
            path: '/o-nas',
            component: AboutUs
        },
        {
            name: 'kontakt',
            path: '/kontakt',
            component: ContactDetails,

        },
        {
            name: 'regulamin',
            path: '/regulamin',
            component: Terms
        },
        {
            name: 'polityka-prywatnosci',
            path: '/polityka-prywatnosci',
            component: PrivacyPolicy
        },
        {
            name: 'baza-wiedzy',
            path: '/baza-wiedzy',
            component: KnowledgeBaseParent,
            redirect: {name: 'index'},
            children: [
                {
                    name: 'index',
                    path: 'index',
                    component: KnowledgeBaseIndex
                },
                {
                    name: 'pierwsze-kroki',
                    path: 'pierwsze-kroki',
                    component: KnowledgeBaseFirstSteps
                },
                {
                    name: 'kierowcy',
                    path: 'kierowcy',
                    component: KnowledgeBaseDrivers
                },
                {
                    name: 'kurierzy',
                    path: 'kurierzy',
                    component: KnowledgeBaseCouriers
                },
                {
                    name: 'flota',
                    path: 'flota',
                    component: KnowledgeBaseFleet
                },
                {
                    name: 'trasy',
                    path: 'trasy',
                    component: KnowledgeBaseRoutes
                },
                {
                    name: 'faktury',
                    path: 'faktury',
                    component: KnowledgeBaseInvoices
                },
                {
                    name: 'rezerwacje',
                    path: 'rezerwacje',
                    component: KnowledgeBaseBookings
                },
                {
                    name: 'ksiazka-adresowa',
                    path: 'ksiazka-adresowa',
                    component: KnowledgeBaseAddressBook
                },
                {
                    name: 'czarna-lista',
                    path: 'czarna-lista',
                    component: KnowledgeBaseBlackList
                },
                {
                    name: 'wiadomosci',
                    path: 'wiadomosci',
                    component: KnowledgeBaseMessages
                },
                {
                    name: 'statystyki',
                    path: 'statystyki',
                    component: KnowledgeBaseStatistics
                },
                {
                    name: 'faq',
                    path: 'faq',
                    component: KnowledgeBaseFaq
                },
                {
                    name: 'filmy-szkoleniowe',
                    path: 'filmy-szkoleniowe',
                    component: KnowledgeBaseVideos
                },
            ]
        },

    ],
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        /*if(savedPosition){
            return savedPosition
        }else{
            const position ={};
            if (to.hash) {
                position.selector = to.hash;
                //position.behavior = 'smooth';
                return false;
            }

        }*/
        if (to.hash){
            return {
                selector: to.hash,
                //offset: { x: 0, y: -400 },
                behavior: 'smooth',
            }
        }else{
            return false;
        }


    },


})

//router.beforeEach((to, from, next) => {
 //   const loggedIn = localStorage.getItem('user')
    //console.log("Inside router", loggedIn)
    //if (to.matched.some(record => record.meta.auth) && !loggedIn) {
       // next('/login')
        //return
    //}
 //   next()
//})
    // Creates a `nextMiddleware()` function which not only
    // runs the default `next()` callback but also triggers
        // the subsequent Middleware function.
///////////////////
/*function nextFactory(context, middleware, index) {
                  const subsequentMiddleware = middleware[index];
                 // If no subsequent Middleware exists,
                // the default `next()` callback is returned.
                if (!subsequentMiddleware) return context.next;
                    return (...parameters) => {
                        // Run the default Vue Router `next()` callback first.
                        context.next(...parameters);
                        // Then run the subsequent Middleware with a new
                            // `nextMiddleware()` callback.
                                const nextMiddleware = nextFactory(context, middleware, index + 1);
                       subsequentMiddleware({ ...context, next: nextMiddleware });
                      };
                }

                router.beforeEach((to, from, next) => {
                    //window.scrollTo(0, 0);
                    if (to.meta.middleware) {
                const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

                const context = {from, next, router, to,};
                const nextMiddleware = nextFactory(context, middleware, 1);

                return middleware[0]({ ...context, next: nextMiddleware });
              }

              return next();
        });*/


export default router;
