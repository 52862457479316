<template>
    <div class="mx-0 bg-white " style="max-width: 100%; width: 100%" >
        <component :is="userComponent" />
    </div>
</template>

<script>
    const Home = () => import(/* webpackPreload: true */ /* webpackChunkName: "preload-home" */ "../frontend/Home")
    import { mapGetters } from 'vuex';
    export default {
        name: "App",
        data() {
            return {
                form: {
                    user : {
                        api_token: 'abde986551900'
                    }

                },
                test: true,
                highlightMenu: 'background-color: #5ce1cf; border-bottom-right-radius: 15px; border-top-right-radius: 15px;',
                expanded: '',
                barStyle: 'hidden absolute right-0 z-10 pl-4 md:block md:relative w-64 border-r-2 md:fix',
                accountDetails: '',
                hover_msg: false,
            };
        },

        async mounted(){
            this.expanded = false;
           // await this.getAccountDetails();
        },
        methods: {
            expandBar () {
                this.expanded = !this.expanded;
                if(this.expanded){
                    this.barStyle = 'absolute left-0 z-10 md:block md:relative md:mt-0 pl-6 w-64 border-r-2 md:fix';
                }else{
                    this.barStyle = 'hidden absolute left-0 z-10 md:block md:relative md:mt-0 pl-6 w-64 border-r-2 md:fix';
                }
            },

        },
        props :[
        ],

        /*created() {
            window.axios.interceptors.request.use(
                (config) => {
                    if(config.method === 'get'){
                        config.url = config.url + '?api_token=' + this.user.api_token;
                    } else {
                        config.data = {
                            ...config.data,
                            api_token: this.user.api_token
                        };
                    }


                    return config;
                }
            )
        }*/
        async created () {

            const userInfo = localStorage.getItem('user')
            if (userInfo) {
                const userData = JSON.parse(userInfo)
                await this.$store.commit('setUserData', userData)
                this.form = userData


            }
            window.axios.interceptors.response.use(
                response => response,
                error => {
                    if (false) { //error.response.status === 401
                        this.$store.dispatch('logout')
                    }
                    return Promise.reject(error)
                }
            )

            window.axios.interceptors.request.use(
                (config) => {
                    if(config.method === 'get' && this.form.user.api_token !== 'test'){
                        config.url = config.url + '?api_token=' + this.form.user.api_token;
                    } else {
                        config.data = {
                            ...config.data,
                            api_token: this.form.user.api_token
                        };
                    }

                    return config;
                },

            )
        },
        computed: {
            ...mapGetters([
                'isLogged',
                'userObject'
            ]),
            user: {
                get() {
                    return this.$store.state.user.user;
                }
            },
            userComponent() {
                return Home;
                //const regExpBackend =
                   // /^\/backend(.*)/;
                //if (true) return Home;
                //if (!regExpBackend.test(this.$route.path)) return Home;
                //if (regExpBackend.test(this.$route.path) && this.$store.state.user.user.is_admin === 1) return BackendHome;
             //   if (this.user && !this.user.subscription)
              //      return User;
               // if (this.user && this.user.subscription)
                //    return Member;
            }
        }
    }
</script>

<style scoped>

</style>
