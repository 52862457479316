import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://go-bus.pl/'

export default new Vuex.Store({
    state: {
        user: null
    },

    mutations: {
        setUserData (state, userData) {
            state.user = userData
            localStorage.setItem('user', JSON.stringify(userData))
            axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
        },

        clearUserData () {
            localStorage.removeItem('user')
            //location.reload()
            //location.href("https://go-bus.pl");
            window.location.href="https://go-bus.pl";
        },
        clearUserData2 () {
            localStorage.removeItem('user')

        },

        clearUserData3 () {
            localStorage.removeItem('user')
            //location.reload()
            //location.href("https://go-bus.pl");
            window.location.href="https://go-bus.pl/login";
        },
    },

    actions: {
        login ({ commit }, credentials) {
            return axios
                .post('/api/login', credentials)
                .then(({ data }) => {
                   // console.log('Inside store', data.user.email_verified_at);
                    if(data.user.email_verified_at){
                        let userDetails = {};
                        userDetails.name = data.user.name;
                        userDetails.surname = data.user.surname;
                        userDetails.email = data.user.email;
                        userDetails.phone = data.user.phone;
                        userDetails.api_token = data.user.api_token;
                        userDetails.code = 200;

                        //console.log('Inside store', sessionLoginForm);
                        commit('setUserData', data);
                        return userDetails;

                    }
                    if(data.user.id != null && data.user.email_verified_at === null){

                    }
                    //
                })
                .catch(err => {
                    //console.log('inside store err', err.response);
                    return err.response;
                })
        },

        logout ({ commit }) {
            commit('clearUserData')
        },
        remove ({ commit }) {
            commit('clearUserData2')
        },
        logoutToLogin ({ commit }) {
            commit('clearUserData3')
        },
    },

    getters : {
        isLogged: state => !!state.user,
        userObject: state => state.user,

    }
})
